* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.rdg-editor-container { z-index: 10051 !important; }

.modal-content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
    box-shadow: 4px 4px grey;
    background-color: white;
    z-index: 100000000000;
}

.btn {
    background-color: teal;
}

.canvas-container {
    
}

::-webkit-scrollbar {
    width: 10px;
    height: 7px;
   }
   ::-webkit-scrollbar-thumb {
    background: linear-gradient(13deg, crimson 14%,crimson 64%);
    border-radius: 7px;
   }
   ::-webkit-scrollbar-thumb:hover{
    background: linear-gradient(13deg, crimson 14%,crimson 64%);
    /* background: linear-gradient(13deg, #c7ceff 14%,#f9d4ff 64%); */
   }
   ::-webkit-scrollbar-track{
    background: linear-gradient(13deg, transparent  14%, transparent  0%);
    border-radius: 7px;
   }

.teste-write-mode::after {
    writing-mode: sideways-lr;
    -ms-writing-mode: sideways-lr;
    text-orientation: sideways;
}